<template>
  <div id="app">
    <Header></Header>
    <keep-alive>
      <router-view class="app-container"/>
    </keep-alive>
    <Footer v-if="$route.meta.showFooter"></Footer>
  </div>
</template>

<script>
  import Header from '@/components/layout/header';
  import Footer from '@/components/layout/footer';
  export default {
    name: 'app',
    components: {
      Header,
      Footer
    },
    beforeMount() {
      let size = (document.documentElement.clientWidth / 1920) * 16;
      document.documentElement.style.fontSize = size > 12 ? size + 'px' : 16  + 'px';
      //axios配置是
    },
  }
</script>
<style>
@font-face{
  font-family: 'PingFangSC';
  src: url('~assets/font/PingFang.ttf');
}
@font-face{
  font-family: 'roboto';
  src: url('~assets/font/Roboto-Regular-14.ttf');
}
* {
  font-family: 'PingFangSC';
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}
body{
  background-color: #EFF3F6 !important;
}
#app {
  min-width: 1400px;
  padding-top: 6.25rem;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  font-family: 'PingFang SC';
  overflow: hidden;
}
.marginLeft {
  margin-left: 22.5rem;
}
.app-container {
  background-color: #FFFFFF;
}
</style>
