import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN.js';
import less from 'less';
import JetCard from '@/components/common/JetCard';
//全局样式
import '@/assets/style/index.less'
//自定义antd样式
import '@/assets/style/ant-design.less'
Vue.config.productionTip = false

Vue.component('jet-card', JetCard)
Vue.use(Antd, {
  local: zhCN
})
Vue.use(less)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
