import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: 'JetLinks 物联网平台', showFooter: true, showFooterBtn: true}
  },
  {
    path: '/production',
    name: 'Production',
    component: () => import('@/views/production'),
    // meta: {title: '产品', showFooter: true, showFooterBtn: false},
    children: [
      {
        path: 'iot',
        name: 'Iot',
        component: () => import('@/views/production/JetlinksIot.vue'),
        meta: {title: '物联网平台', showFooter: true, showFooterBtn: true}
      },
      {
        path: 'quick_access',
        name: 'QuickAccess',
        component: () => import('@/views/production/QuickAccess.vue'),
        meta: {title: '产品接入', showFooter: true, showFooterBtn: false}
      },
      {
        path: 'edge',
        name: 'Edge',
        component: () => import('@/views/production/JetlinksEdge.vue'),
        meta: {title: '物联网平台边缘网关', showFooter: true, showFooterBtn: true}
      },
      {
        path: 'view',
        name: 'View',
        component: () => import('@/views/production/JetlinksView.vue'),
        meta: {title: '物联网可视化平台', showFooter: true, showFooterBtn: true}
      },
    ]
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('@/views/solution/index.vue'),
    meta: {title: '解决方案',showFooter: false, showFooterBtn: false},
    children: [
      {
        path: 'industry',
        name: 'Industry',
        component: () => import('@/views/solution/Industry.vue'),
        meta: {title: '智慧工业', showFooter: false, showFooterBtn: false}
      },
      {
        path: 'city',
        name: 'City',
        component: () => import('@/views/solution/City.vue'),
        meta: {title: '智慧城市', showFooter: false, showFooterBtn: false}
      },
      {
        path: 'construction',
        name: 'Construction',
        component: () => import('@/views/solution/Construction.vue'),
        meta: {title: '智慧工地', showFooter: false, showFooterBtn: false}
      }
    ]
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('@/views/Company.vue'),
    meta: {title: 'JetLinks团队', showFooter: true, showFooterBtn: true}
  },
  {
    path: '/contact_us',
    name: 'ContactUs',
    component: () => import('@/views/JetlinksContact.vue'),
    meta: {title: '联系我们', showFooter: true, showFooterBtn: true}
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next()
})
export default router
