<template>
  <!-- 平台特性 -->
  <div class="platform">
    <jet-card title="产品功能" subTitle="物联网应用和物联网设备之间搭建高效、稳定、安全的应用平台">
      <ul class="card-list">
        <li class="card-list-item">
          <img src="@/assets/images/home/product1.png" class="symbol">
          <h4 class="title">设备接入</h4>
          <p class="content">多元化设备接入方式，提供基于网络协议、云平台、边缘网关、视频通道等多种设备接入方式。</p>
        </li>
        <li class="card-list-item">
          <img src="@/assets/images/home/product2.png" class="symbol">
          <h4 class="title">数据处理</h4>
          <p class="content">提供基于ReactorQL、自定义脚本计算、规则引擎等强大的数据处理功能。</p>
        </li>
        <li class="card-list-item">
          <img src="@/assets/images/home/product3.png" class="symbol">
          <h4 class="title">数据储存</h4>
          <p class="content">平台支持Elasticsearch、ClickHouse、InfluxDB、TDengine、Cassandra等数据存储中间件，可自定义配置。</p>
        </li>
        <li class="card-list-item">
          <img src="@/assets/images/home/product4.png" class="symbol">
          <h4 class="title">应用集成</h4>
          <p class="content">平台提供所有功能API接口，第三方应用可轻松获取平台数据；同时支持Oauth2单点登录。</p>
        </li>
      </ul>
      <ul class="card-list">
        <li class="card-list-item">
          <img src="@/assets/images/home/product5.png" class="symbol">
          <h4 class="title">规则引擎</h4>
          <p class="content">提供基于可视化规则编辑器，以拖拉拽的方式处理数据输入、清洗、计算、输出、存储等业务。</p>
        </li>
        <li class="card-list-item">
          <img src="@/assets/images/home/product6.png" class="symbol">
          <h4 class="title">通知管理</h4>
          <p class="content">平台支持钉钉、微信、邮箱、短信、webhook、语音6大类通知方式。</p>
        </li>
        <li class="card-list-item">
          <img src="@/assets/images/home/product7.png" class="symbol">
          <h4 class="title">告警中心</h4>
          <p class="content">支持设备和定时维度实时触发告警规则。</p>
          <p class="content">支持告警推送、告警阈值、告警解除、告警限频等功能。</p>
        </li>
        <li class="card-list-item">
          <img src="@/assets/images/home/product8.png" class="symbol">
          <h4 class="title">视频</h4>
          <p class="content">平台基于GB/T28181/2016协议支持多路视频直播、回放、录制、云台控制、国标级联。</p>
        </li>
      </ul>
    </jet-card>
  </div>
</template>

<script>
import JetCard from '@/components/common/JetCard';
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    JetCard
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  
  .card-list{
    width: 75rem;
    margin: 1rem auto 0 auto;
    display: flex;
    .card-list-item {
      width: 18.375rem;
      height: 20rem;
      margin-right: 2rem;
      margin-bottom: 1.3rem;
      background-color: #FFFFFF;
      box-sizing: border-box;
      text-align: center;
      border: 1px solid rgba(30, 60, 96, 0);
      padding: 2.25rem;
      color: #000000;
      border: 1px solid rgba(30, 60, 96, 0.1);
      box-shadow: 0px 4px 10px rgba(20, 54, 94, 0.1);
      border-radius: 12px;
      transition: all .2s linear;
      &:hover{
        transform: scale(1.05);
      }
      .symbol {
        margin: 0 auto;
      }
      .title {
        margin-top: 20px;
        font-weight: 700;
        font-size: 1.5rem;
        color: #0E6EFF;
        margin-bottom: 1.5rem !important;
        padding: 0 !important;
      }
      .content {
        font-size: 1rem;
        margin: 0;
        text-align: left;
        color: rgba(11, 17, 69, 0.8);
      }
    }
  }
</style>