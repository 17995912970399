<template>
  <!-- JetLinks接入点 -->
  <div class="access-point">
    <jet-card title="JetLinks接入点" subTitle="JetLinks的定位为Paas服务的物联网平台，降低物联网企业研发、运营和运维成本，提高物联网项目的落地能力。" bgColor="#EBF0FC">
      <div class="static">
        <a-row justify="space-around">
          <a-col :span="8">
            <div class="static-item">
              <img src="@/assets/images/home/static1.png">
              <h3>10+</h3>
              <p>应用场景</p>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="static-item">
              <img src="@/assets/images/home/static2.png">
              <h3>200+</h3>
              <p>企业用户</p>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="static-item">
              <img src="@/assets/images/home/static3.png">
              <h3>200w+</h3>
              <p>设备接入</p>
            </div>
          </a-col>
        </a-row>
      </div>
    </jet-card>
  </div>
</template>

<script>
export default {
  name: 'AccessPoint',
  data() {
    return {
       
    }
  },
  components: {
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .access-point {
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
    padding-bottom: 5rem;
    background-color: #EBF0FC;
    .static {
      width: 76.875rem;
      height: 13.125rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px 2px rgba(55, 69, 126, 0.13);
      border-radius: 1rem;
      position: absolute;
      bottom: -6rem;
      .static-item{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h3{
          font-size: 3rem;
          color: #0E6EFF;
          margin: 0;
        }
        p{
          color: #061437;
          font-size: 1.125rem;
          margin: 0;
        }
      }
    }
  }
</style>