<template>
  <div class="jet-card" :style="{background: bgColor}">
    <div class="title" v-if="titleType == 1">
      <h3><span></span>{{title}}<span></span></h3>
      <p class="subtitle">{{subTitle}}</p>
    </div>
    <div class="title" v-else>
      <h3>{{title}}</h3>
      <div></div>
      <p v-if="subTitle.length > 0" class="subtitle">{{subTitle}}</p>
    </div>
    <div :class="{container: !fullWidth}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: '#FFFFFF'
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    titleType: {
      type: Number,
      default: 1
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .jet-card {
    background-color: #FFFFFF;
    width: 100%;
    padding: 5.75rem 0;
    .title{
      color: #000000;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3{
        font-size: 2.5rem;
        span{
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #3055EA;
          border-radius: 50%;
          margin: 0.2rem 0.5rem;
          position: relative;
          &:first-child{
            &::before{
              content: '';
              width: 48px;
              height: 2px;
              background: #3055EA;
              position: absolute;
              left: -52px;
              top: 2px;
            }
          }
          &:last-child{
            &::after{
              content: '';
              width: 48px;
              height: 2px;
              background: #3055EA;
              position: absolute;
              left: 12px;
              top: 2px;
            }
          }
        }
      }
      .subtitle{
        font-size: 1.5rem;
        width: 60%;
        line-height: 36px;
      }
      div{
        width: 8px;
        height: 8px;
        background: #3055EA;
        border-radius: 50%;
        position: relative;
        &::before{
          content: '';
          width: 7.375rem;
          height: 2px;
          background: #3055EA;
          position: absolute;
          left: calc(-7.375rem - 8px);
          top: 3px;
        }
        &::after{
          content: '';
          width: 7.375rem;
          height: 2px;
          background: #3055EA;
          position: absolute;
          left: calc(16px);
          top: 3px;
        }
      }
      p{
        color: rgba(6, 20, 55, 0.6);
        line-height: 1.25rem;
        margin: 0;
        margin: 2rem 0;
      }
    }
  }
</style>