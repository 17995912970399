<template>
  <!-- 合作伙伴 -->
  <div class="partners">
    <jet-card title="合作伙伴" :fullWidth="true">
      <div class="scroll">
        <div class="partners-list">
          <div class="partners-item" v-for="item, index in imgArr" :key="index" >
            <img :src="item.src"/>
          </div>
        </div>
      </div>
    </jet-card>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      imgArr: []
    }
  },
  created() {
    const arr = ['中国联通', '']
    for(let i = 1; i <= 35; i++) {
      this.imgArr.push({
        src: require(`@/assets/images/home/partner${i}.png`)
      })
    }
  },
  components: {

  },
  methods: {

  },
}
</script>
<style scoped lang="less">
  .scroll{
    width: 100%;
    overflow: hidden;
    justify-content: flex-start;
    &:hover{
      .partners-list{
        animation-play-state: paused;
      }
    }
  }
.partners-list {
  width: 75%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  .partners-item {
    width: 18%;
    height: 96px;
    margin: 1% 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

</style>