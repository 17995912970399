<template>
  <div class="jetlinks">
    <a-row style="display: flex;justify-content: space-between">
      <a-col :span="10">
        <div class="left">
          <p><span>JetLinks</span></p>
          <h1>开源物联网基础平台</h1>
          <p>设备管理，多协议适配，数据收集，规则引擎，快速构建物联网相关业务系统</p>
          <div>
            <a-button type="primary">
              <a href="https://github.com/jetlinks/jetlinks-community" target="_blank">Github→</a>
            </a-button>
            <a-button type="primary" ghost>
              <a href="https://gitee.com/jetlinks/jetlinks-community/tree/2.0/" target="_blank">Gitee→</a>
            </a-button>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <img src="@/assets/images/home/Component 22.png" style="width: 100%;min-width: 500px;">
      </a-col>
    </a-row>
    <div class="page">
      <div class="page-swiper">
        <div class="swiper-container">
          <a-carousel :autoplay="true">
              <img src="@/assets/images/home/views1.png" />
              <img src="@/assets/images/home/views2.png" />
              <img src="@/assets/images/home/views3.png" />
          </a-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {

    }
  },
  components: {

  },
  methods: {

  },
}
</script>
<style scoped lang="less">
.jetlinks {
  .ant-row {
    width: 70%;
    margin: 0 auto;
    .ant-col {
      height: 100%;

      .left {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 60px;

        button {
          border-radius: 24px !important;
          padding: 0 2.4rem !important;
          height: 2.5rem !important;
          box-shadow: 0px 4px 10px rgba(22, 43, 127, 0.2);
          margin-top: 2rem;
          font-size: 1rem;
          margin-right: 1.5rem;
          &:last-child{
            padding: 0 2rem !important
          }
        }
      }
    }

    span {
      font-family: 'PingFangSC';
      font-style: normal;
      font-weight: 600;
      font-size: 3.625rem;
      background-image: linear-gradient(266.88deg, #B20EFF 26.28%, #5375FE 79.94%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    h1 {
      font-size: 3.75rem;
      color: #0B1145;
      font-weight: 600;
      white-space: nowrap;
    }

    p {
      font-size: 1.5rem;
      color: #0B1145;
    }
  }
  .page{
    width: 100%;
    margin-top: 6.5rem;
    background: url('~assets/images/home/jetlinks-bg.png') no-repeat center center/100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .page-swiper{
      width: 1256px;
      height: 809px;
      background: url('~assets/images/home/Macbook.png') no-repeat center center/100% 100%;
      .swiper-container{
        width: 73%;
        height: 80%;
        padding-top: 4%;
        :deep(.slick-slide){
          text-align: center;
          width: 100%;
          height: 600px;
          overflow: hidden;
          color: #ffffff;
        }
        :deep(.slick-dots.slick-dots-bottom) {
          li{
            margin: 0 10px;
          }
          button{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #0E6EFF !important;
          }
        }
      }
    }
  }
}
</style>