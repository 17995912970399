<template>
  <div class="header">
    <Logo></Logo>
    <Menus></Menus>
    <user-operate></user-operate>
  </div>
</template>

<script>
import Logo from './Logo.vue';
import Menus from './Menus';
import UserOperate from './UserOperate';
export default {
  name: '',
  data() {
    return {
      
    }
  },
  components: {
    Logo,
    Menus,
    UserOperate
  },
  methods: {
    
  },
}
</script>
<style scoped>
  /* 头部 */
  .header {
    width: 100%;
    min-width: 1400px;
    height: 6.25rem;
    padding: 0 12.5rem;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    z-index: 99;
    color: #000000;
    font-size: 1rem;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  }
  
</style>