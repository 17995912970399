<template>
  <div class="area-covered">
    <jet-card title="涉及领域" subTitle="JetLinks于2019年上线，已服务超过200家企业，横跨各领域行业" :fullWidth="true">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide>
          <div class="area-item">
            <div class="content">
              <h1>智慧城市</h1>
              <p>利用物联网、云计算、大数据、人工智能、可视化等技术构建城市物联网平台，承载市政、能源、公共安全、环保、消防、交通等多个行业的感知终端接入。</p>
            </div>
          </div>
          <div class="area-item">
            <div class="content">
              <h1>智慧工业</h1>
              <p>平台设计开发要符合当前物联网平台的业务需求，从提高生产效率、实现节能减排和完成产业结构调整出发，致力于智能化管理模式的创新。包括智能的感知控制、全面的互联互通、深度的数据应用、创新的服务模式。</p>
            </div>
          </div>
          <div class="area-item">
            <div class="content">
              <h1>智慧工地</h1>
              <p>通过物联网、人工智能、大数据技术，围绕工地人的不安全因素、设备设施的规范使用和工地的安全管理，分析建筑工程安全场景、安全隐患感知、超前预警、安全要素防篡改，实现对工地安全集中管理和监管的制度化、标准化和智慧化。</p>
            </div>
          </div>
          <div class="area-item">
            <div class="content">
              <h1>智慧桥隧</h1>
              <p>现代桥梁与隧道综合性指挥平台，综合运用地理信息系统（GIS）、大规模网络通信、海量数据存储、物联网、数字孪生等先进技术手段以三维方式将隧道与桥梁的设备状态、环境、能耗、人员、车流等数据进行可视化呈现与控制，能够有效、及时地做到事前预警、设备控制、事中监控、事后分析，极大提高路段的管理效率，提升路段的安全指数与设备的养护，推动路段的智能化发展。</p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </jet-card>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper' //引入swiper组件
import 'swiper/css/swiper.css' //引入swiper组件css样式
export default {
  name: '',
  data() {
    return {
      swiperOption: { //swiper组件配置
        slidesPerView: 1, //一次显示多少个
        spaceBetween: 0, //每个slide间的间隔
        autoplay: 20, //可选选项，自动滑动
        loop: true,
        navigation: { // 左右按钮分页器
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {

  },
}
</script>
<style scoped lang="less">
.swiper {
  margin-top: 3.125rem;

  .swiper-slide {
    display: flex;
    .area-item{
      width: 25%;
      height: 41.75rem;
      transition: all .1s linear;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: relative;
      &:nth-child(1) {
        background-image: url('~assets/images/home/home-area-swiper1.svg');
      }
      &:nth-child(2) {
        background-image: url('~assets/images/home/home-area-swiper3.svg');
      }
      &:nth-child(3) {
        background-image: url('~assets/images/home/home-area-swiper2.svg');
      }
      &:nth-child(4) {
        background-image: url('~assets/images/home/home-area-swiper4.svg');
      }
      &:hover{
        width: 41.75rem !important;
        flex: none;
        &::after{
          background-color: rgba(0, 0, 0, 0);
        }
        .content{
          height: 40%;
          background-color: rgba(0, 0, 0, 0.3);
          h1{
            border: none;
          }
          p{
            display: block;
          }
        }
      }
      &::after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 0;
      }
      .content {
        width: 100%;
        height: 100%;
        padding: 3.25rem;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all .3s ease;
        h1{
          color: #FFFFFF;
          border-bottom: 7px solid #0E6EFF;
        }
        p{
          display: none;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background: rgba(25, 25, 25, 0.1);
    padding: 28px 9.5px;
    color: #FFFFFF !important;
  }
}
</style>