<template>
  <div class="operate">
    <span class="github-stars">
      <router-link to="/contact_us"><span class="stars">联系我们</span></router-link>
    </span>
    <a-button type="primary" class="btns">
      <a href="https://demo.jetlinks.cn/" target="_blank" >免费试用</a>
    </a-button>
  </div>
</template>

<script>
export default {
  name: 'userOperate',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .operate{
    width: 21.875rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    color: #000000;
  }
  .github-stars{
    margin-left: 1.5625rem;
    margin-right: 1.5rem;
    white-space: nowrap;
    cursor: pointer;
  }
  .stars{
    margin-left: 0.5rem;
  }
  .search-btn{
    cursor: pointer;
  }
  .btns{
    margin-right: 2.5rem;
    background: #3055EA;
  }
  a{
    &:hover{
      color: #3055EA;
    }
  }
</style>  