<template>
  <div class="logo">
    <router-link :to="{path: '/'}"><img src="@/assets/logo.svg"></router-link>
  </div>
</template>

<script>
export default {
  name: 'logo',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped>
  .logo {
    width: 140px;
    line-height: 100px;
    cursor: pointer;
    margin-left: 20px;
  }
</style>