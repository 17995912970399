<template>
  <div class="home">
    <jet-links></jet-links>
    <PlatFormFeatures></PlatFormFeatures>
    <AccessPoint></AccessPoint>
    <ProductFunction></ProductFunction>
    <AreaCovered></AreaCovered>
    <!-- <Partners></Partners>
     -->
  </div>
</template>

<script>
import JetLinks from '@/components/home/JetLinks';
import PlatFormFeatures from '@/components/home/PlatFormFeatures';
import AccessPoint from '@/components/home/AccessPoint';
import ProductFunction from '@/components/home/ProductFunction';
import AreaCovered from '@/components/home/AreaCovered';
import Partners from '@/components/home/Partners';
export default {
  name: 'Home',
  components: {
    JetLinks,
    PlatFormFeatures,
    AccessPoint,
    ProductFunction,
    AreaCovered,
    Partners
  }
}
</script>

<style scoped lang="less">
  
</style>
